@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: #007bff;
  text-decoration: underline;
}

.custom-width {
  max-width: 80%;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.align-right {
  margin-left: auto;
  text-align: right;
  background-color: #dcffe4; /* Light green background for user messages */
}

.align-left {
  margin-right: auto;
  text-align: left;
  background-color: #e7f0fd; /* Light blue background for AI messages */
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.message-icon {
  margin: 0 10px;
  cursor: pointer;
}

/* Enhance feedback interaction visibility */
.like-button, .dislike-button {
  padding: 5px;
  border-radius: 50%;
}

.like-button.active, .dislike-button.active {
  background-color: #ffe0b2; /* Soft orange for active state */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-width {
    max-width: 90%;
    margin-top: 10px;
  }
}

.user-text {
  margin-right: 10px; /* Adjust this value as needed */
}

/* If you have specific classes for the text and circle */
.flex-row-reverse .text-sm.font-semibold {
  margin-right: 5px; /* Adds space to the right of the text */
}

.flex-row-reverse .w-6.h-6 {
  margin-left: 5px; /* Adds space to the left of the circle */
}
.text-3xl {
  font-size: 24px; /* Adjust size as necessary */
  line-height: 1.2; /* Adjust line height to ensure vertical alignment if needed */
}

